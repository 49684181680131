// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming'
import { Link } from 'gatsby'
import {
  Button,
  Hero,
  Icon,
  Card,
  CallToAction,
  Carousel,
  Section,
  text,
} from '@toggl/ui'
import { colors, fonts, media, themes } from '@toggl/style'
import IndexLayout from '../layouts'
import LayoutGlobal from '../components/LayoutGlobal'
import { Video } from 'components/video'

declare var globalThis: any

const HeroComponent = ({ children }) => {
  return (
    <Hero.Wrapper
      bgImage={require('assets/homepage-hero-bg.jpg')}
      bgVideo={require('assets/bg-video.mp4')}
    >
      {children}
      <ThemeProvider theme={themes.track}>
        <Hero.ContentWrapper>
          <Hero.Title>
            It’s time <br />
            to get your
            <br /> team on <Rotalic>Track</Rotalic>.
          </Hero.Title>

          <LogoWrapper>
            <Icon.Track />
          </LogoWrapper>

          <Hero.Text>Realiable time tracking loved by 5M users.</Hero.Text>

          <Hero.CtaSection>
            <Link to={'/track/signup'}>
              <Button.Primary>Sign-up for free</Button.Primary>
            </Link>
            <Link to={'/track'}>
              <Button.Secondary color={colors.almostWhite}>
                Or discover more
              </Button.Secondary>
            </Link>
          </Hero.CtaSection>
        </Hero.ContentWrapper>
      </ThemeProvider>
    </Hero.Wrapper>
  )
}

const IndexPage = () => {
  const isSeen =
    globalThis?.localStorage && localStorage.getItem('topbar-closed') === 'true'
  const [open, setOpen] = React.useState(!isSeen)
  return (
    <IndexLayout
      title={'Toggl: Simple & Beautiful Tools that Help Teams Work Better'}
      description={
        'We take the stress out of time-tracking, project-planning, and hiring. Designed by and for teams that work from anywhere.'
      }
    >
      <StyledTopbar open={open}>
        <TopbarWrapper>
          <p>
            Toggl is now Toggl Track! We're still the Toggl you know and love,
            but we've welcomed Toggl Plan and Toggl Hire into the family 🤗{' '}
            <a href="/">Read more.</a>
          </p>
          <span
            onClick={() => {
              if (globalThis?.localStorage) {
                localStorage.setItem('topbar-closed', 'true')
              }
              setOpen(false)
            }}
          >
            Close
          </span>
        </TopbarWrapper>
      </StyledTopbar>

      <LayoutGlobal Hero={HeroComponent}>
        <Section.Block background={colors.darkPurple} flex>
          <ThemeProvider theme={themes.plan}>
            <Card.Product
              Logo={Icon.Plan}
              title={'Beautifully simple project planning.'}
              video={<Video src={require('assets/toggl-plan.mp4')} />}
              Cta={() => (
                <a href={'/plan/'} target="_blank" rel="noreferrer">
                  <Button.Primary>Start planning for free</Button.Primary>
                </a>
              )}
            />
          </ThemeProvider>
          <ThemeProvider theme={themes.track}>
            <Card.Product
              Logo={Icon.Track}
              title={'Effortless time tracking.'}
              video={<Video src={require('assets/toggl-track.mp4')} />}
              Cta={() => (
                <a href={'/track/'} target="_blank" rel="noreferrer">
                  <Button.Primary>Start tracking for free</Button.Primary>
                </a>
              )}
            />
          </ThemeProvider>
          <ThemeProvider theme={themes.hire}>
            <Card.Product
              Logo={Icon.Hire}
              title={'Smarter & faster candidate screening.'}
              video={<Video src={require('assets/toggl-hire.mp4')} />}
              Cta={() => (
                <a href={'/hire/'} target="_blank" rel="noreferrer">
                  <Button.Primary>Start hiring for free</Button.Primary>
                </a>
              )}
            />
          </ThemeProvider>
        </Section.Block>
        <Section.Block center background={colors.darkPurple}>
          <CallToAction
            size={1}
            title={
              <span>
                Work <Rotalic>Smarter</Rotalic>,
                <br />
                Live Happier
              </span>
            }
            subTitle={
              <>
                <span>
                  Our global team comes together every day to move our mission
                  forward - making simple, elegant tools to help you be
                  productive without the stress.
                </span>
                <MissionLinkWrapper>
                  <Link to="/mission">
                    <Button.Secondary color={colors.beige}>
                      See what drives us
                    </Button.Secondary>
                  </Link>
                </MissionLinkWrapper>
              </>
            }
          />
          <StyledImage
            src={require('assets/killstress-dark.gif')}
            alt={'Work smarter, live happier'}
            loading="lazy"
          />
        </Section.Block>
        <Section.Skewed
          color={colors.fadedPurple}
          backgroundColor={colors.darkPurple}
        >
          <Wrapper>
            <CtaParagraphSectionSecond>
              <CallToAction
                size={2}
                title={<span>Knowledge is Power</span>}
                subTitle={
                  <span>
                    Get your remote-working, time-tracking, project-planning,
                    and hiring best practices here! Straight from the experts at
                    Toggl.
                  </span>
                }
                Cta={() => (
                  <Link to={'https://blog.toggl.com'}>
                    <Button.Primary>See More on Our Blog</Button.Primary>
                  </Link>
                )}
                image={require('assets/megaphone-dark.gif')}
              />
            </CtaParagraphSectionSecond>
          </Wrapper>
        </Section.Skewed>
        <Section.Block>
          <Carousel.DotMobile>
            <Card.ReadMore
              title={
                'Everything we’ve learned from the last seven years of remote work.'
              }
              category={'How To Work From Home'}
              href={'https://toggl.com/work-from-home/'}
              image={
                <img
                  src={require('assets/resource-work-from-home.png')}
                  alt={'Toggl Work From Home Hub'}
                />
              }
            />
            <Card.ReadMore
              title={
                'The secret behind any happy collaboration usually lies in the skill of managing expectations.'
              }
              category={'The Expectations Game'}
              href={
                'https://toggl.com/plan/blueprint-the-expectations-game/index'
              }
              image={
                <img
                  src={require('assets/resource-toggl-plan-blueprint.png')}
                  alt={'The Expectations Game Blueprint'}
                />
              }
            />
            <Card.ReadMore
              title={'Hiring Remotely: A Practical Guide'}
              category={'Hiring Remotely'}
              href={'https://ebook.hire.toggl.com/'}
              image={
                <img
                  src={require('assets/resource-hiring-remotely.png')}
                  alt={'Hiring Remotely Online Guide'}
                />
              }
            />
          </Carousel.DotMobile>
        </Section.Block>
      </LayoutGlobal>
    </IndexLayout>
  )
}

const StyledImage = styled.img`
  width: 320px;

  ${media.mq[1]} {
    width: 600px;
  }
`

const StyledTopbar = styled.div`
  ${(p) => (!p.open ? 'display: none;' : '')}
  min-height: 60px;
  background: ${colors.yellow};
  padding: 20px;

  p,
  a {
    ${text.paragraph3};
    color: ${colors.darkPurple};
  }

  a {
    text-decoration: underline;
    display: inline-block;
    color: ${colors.darkPurple};
  }

  span {
    margin-top: 10px;
    display: block;
    cursor: pointer;
  }

  ${media.mq[1]} {
    span {
      margin-top: 0;
      margin-left: 20px;
    }
  }

  position: relative;
`
const TopbarWrapper = styled.div`
  ${media.mq[1]} {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const MissionLinkWrapper = styled.span`
  margin-top: 20px;
  display: block;
`

const CtaParagraphSection = styled.div`
  padding: 100px 10px 127px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    max-width: 320px;
    ${media.mq[1]} {
      max-width: 840px;
    }
  }

  ${media.mq[1]} {
    padding: 75px 10px 0 10px;
  }
`

const CtaParagraphSectionSecond = styled(CtaParagraphSection)`
  transform: translateY(-120px);
  ${media.mq[1]} {
    transform: translateY(-80px);
  }
`

const LogoWrapper = styled.div`
  svg {
    width: 120px;
  }
  ${media.mq[1]} {
    svg {
      width: 167px;
    }
  }
`

const Rotalic = styled.span`
  font-family: ${fonts.gtHaptikMediumRotalic};
  color: ${(p) => p.theme.primary};
`

export default IndexPage
